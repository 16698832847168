import { UserProfilePayload } from "@packages/services/src/http/get-me";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import LocalStorage from "../../helpers/localStorage";
import { UserState } from "./types";

export function getPersistedDelegatedUser(): UserState["delegatedUser"] {
  return LocalStorage.getItem("user.delegatedUser")
    ? JSON.parse(LocalStorage.getItem("user.delegatedUser") || "")
    : null;
}

// Initial slice state
const initialState: UserState = {
  delegatedUser: getPersistedDelegatedUser(),
};

// Slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    validateCurrentDelegatedUser(
      state,
      action: PayloadAction<{ userDelegations: UserProfilePayload["userDelegations"] }>
    ) {
      const { userDelegations } = action.payload;
      const hasCurrentDelegatedUser = userDelegations.some(
        (u) => u.delegatedUserId && u.delegatedUserId === state.delegatedUser?.delegatedUserId
      );
      if (state.delegatedUser && !hasCurrentDelegatedUser) {
        state.delegatedUser = null;
        LocalStorage.removeItem("user.delegatedUser");
      }
    },
    setCurrentDelegatedUser(
      state,
      action: PayloadAction<{ delegatedUser: UserState["delegatedUser"] }>
    ) {
      const { delegatedUser } = action.payload;
      state.delegatedUser = delegatedUser;
      LocalStorage.setItem("user.delegatedUser", JSON.stringify(delegatedUser));
    },
  },
});

export default userSlice;
