import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { useDispatch } from "react-redux";

import { appApi, appNoCacheApi } from "../app/api";
import authSlice, { signOut } from "../auth/slice";
import contactSlice from "../contact/slice";
import { geoApi } from "../geo/api";
import { clear } from "../idbKeyVal";
import userSlice from "../user/slice";

const appReducer = combineReducers({
  [appApi.reducerPath]: appApi.reducer,
  [appNoCacheApi.reducerPath]: appNoCacheApi.reducer,
  [geoApi.reducerPath]: geoApi.reducer,
  [authSlice.name]: authSlice.reducer,
  [contactSlice.name]: contactSlice.reducer,
  [userSlice.name]: userSlice.reducer,
});

function deleteAllCookies() {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

function clearAllStorage() {
  localStorage.clear();
  clear();
  deleteAllCookies();
}

const store = configureStore({
  reducer: (state, action): AppState => {
    if (signOut.pending.match(action)) {
      // reset entire redux state upon signing out
      state = undefined;
      clearAllStorage();
    }
    return appReducer(state, action);
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(appApi.middleware).concat(appNoCacheApi.middleware),
});

setupListeners(store.dispatch);

export type AppState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
