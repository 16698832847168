import "../styles/globals.css";
import "../styles/tooltip.css";
import "@fortawesome/fontawesome-svg-core/styles.css";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { config } from "@fortawesome/fontawesome-svg-core";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useUpdateUserTimeZone } from "@web/hooks/useUpdateUserTimeZone";
import { useUpdateUserTimeZoneMutation } from "@web/integrations/user/api";
import type { AppProps } from "next/app";
import Script from "next/script";
import * as process from "process";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Provider as ReduxProvider } from "react-redux";
import smartlookClient from "smartlook-client";

import { PageWithLayout } from "../hocs/types";
import store from "../integrations/redux/store";

// https://fontawesome.com/v6.0/docs/web/use-with/react/use-with#next-js
config.autoAddCss = false;

// Configure bugsnag
Bugsnag.start({
  apiKey: "f95a863a477411e74e9b88e06db6fedf",
  plugins: [new BugsnagPluginReact() as any],
  releaseStage: process.env.NEXT_PUBLIC_ENVIRONMENT,
  enabledReleaseStages: ["production"],
  appVersion: process.env.WEB_APP_VERSION,
  onError: function (event) {
    // Discard ResizeObserver errors
    return !event.errors.find((e) => e.errorMessage.includes("ResizeObserver"));
  },
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

if (typeof window !== "undefined") {
  // catch the resizer observer error
  // https://github.com/petyosi/react-virtuoso/issues/254
  // can be safely ignored
  window.addEventListener("error", (e) => {
    if (
      e.message === "ResizeObserver loop completed with undelivered notifications." ||
      e.message === "ResizeObserver loop limit exceeded"
    ) {
      e.stopImmediatePropagation();
    }
  });
}

function unregisterSW() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(async function (registrations) {
      for (const registration of registrations) {
        await registration.unregister().catch((e) => console.warn(e));
      }
    });
  }
}

const TitledockApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    smartlookClient.init("47d082f396a222841bf3e0187197f42ad63a9c66");
    unregisterSW();
  }, []);

  // Grab per-page layout info
  const getLayout = (Component as PageWithLayout).getLayout || ((page) => page);

  return (
    <ErrorBoundary>
      <ReduxProvider store={store}>
        <>
          <Script src="/flexsearch/flexsearch@0.7.22.bundle.js" strategy="beforeInteractive" />
          <Script src="/flexsearch/lang/us.min.js" strategy="beforeInteractive" />
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CONTACT_SYNC_CLIENT_ID!}>
            {getLayout(<Component {...pageProps} />)}
          </GoogleOAuthProvider>
          <Toaster />
        </>
      </ReduxProvider>
    </ErrorBoundary>
  );
};

export default TitledockApp;
