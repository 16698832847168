import Bugsnag from "@bugsnag/js";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "firebase/auth";

import { auth } from "../firebase";
import { AuthState } from "./types";

// Initial slice state
const initialState: AuthState = {
  authenticatedSession: undefined,
  explicitlySignedOut: false,
};

export async function getSessionData(user: User) {
  return {
    displayName: user.displayName,
    photoUrl: user.photoURL,
    email: user.email,
    sub: user.uid,
    idToken: await user.getIdToken(),
    refreshToken: user.refreshToken,
  };
}

export const signIn = createAsyncThunk("/auth/signIn", async (user: User) => {
  try {
    return await getSessionData(user);
  } catch (error) {
    console.warn(error);
  }
  return undefined;
});

export const signOut = createAsyncThunk("auth/signOut", async () => {
  try {
    console.log("signing out...");
    // Sign out of cognito + amplify
    await auth.signOut();
    // Sign out of Bugsnag
    Bugsnag.setUser(undefined, undefined, undefined);
  } catch (e) {
    console.log(e);
  }
});

// Slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signOut.pending, (state) => {
      state.authenticatedSession = undefined;
      state.explicitlySignedOut = true;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.authenticatedSession = action.payload;
      state.explicitlySignedOut = !action.payload;
    });
  },
});

export default authSlice;
