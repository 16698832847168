import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { BaseQueryFn, fetchBaseQuery, retry } from "@reduxjs/toolkit/dist/query/react";

import { auth } from "../firebase";
import { getPersistedDelegatedUser } from "../user/slice";
import { AppState } from "./store";

export const getBaseQuery = (queryArgs: FetchBaseQueryArgs = {}): BaseQueryFn => {
  const handler = async (args: any, api: BaseQueryApi, extraOptions: object) => {
    const state = api.getState() as AppState;

    const baseQuery = fetchBaseQuery({
      ...queryArgs,
      prepareHeaders: async (headers) => {
        const token = await auth.currentUser?.getIdToken();
        if (token) {
          // If we have a token set in state, let's assume that we should be passing it.
          headers.set("Authorization", `Bearer ${token}`);
        }
        const delegatedUserId = state.user.delegatedUser?.delegatedUserId;
        if (delegatedUserId) {
          headers.set("delegated-user-id", delegatedUserId);
        }
        return headers;
      },
    });

    return baseQuery(args, api, extraOptions);
  };
  return retry(handler, { maxRetries: 2 });
};

export async function queryFetch<T, B = void>(
  uri: string,
  method: "GET" | "POST" | "DELETE" = "GET",
  body?: B
): Promise<{ data?: T; error?: any; headers?: any }> {
  const token = await auth.currentUser?.getIdToken();

  const headers: { [x: string]: string } = {
    Authorization: `Bearer ${token}`,
  };
  const delegatedUser = getPersistedDelegatedUser();

  if (delegatedUser && delegatedUser.delegatedUserId) {
    headers["delegated-user-id"] = delegatedUser.delegatedUserId;
  }

  const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}${uri}`, {
    headers,
    method,
    body: body ? JSON.stringify(body) : undefined,
  });

  if (res.status < 300) {
    try {
      const data = (await res.json()) as T;
      return { data, headers: res.headers };
    } catch (error) {
      return { error };
    }
  }

  return {};
}
