import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ContactState } from "./types";

// Initial slice state
const initialState: ContactState = {
  multiSelectedContacts: [],
  selectedGroups: [],
};

// Slice
const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setMultiSelectedContacts(
      state,
      action: PayloadAction<{ contacts: ContactState["multiSelectedContacts"] }>
    ) {
      const { contacts } = action.payload;
      state.multiSelectedContacts = contacts;
    },
    clearMultiSelectedContacts(state) {
      state.multiSelectedContacts = [];
    },
    setSelectedGroups(state, action: PayloadAction<{ groups: ContactState["selectedGroups"] }>) {
      const { groups } = action.payload;
      state.selectedGroups = groups;
    },
    removeSelectedGroup(state, action: PayloadAction<{ groupId: string }>) {
      const { groupId } = action.payload;
      state.selectedGroups = state.selectedGroups.filter((group) => group.id !== groupId);
    },
    clearSelectedGroups(state) {
      state.selectedGroups = [];
    },
  },
});

export default contactSlice;
