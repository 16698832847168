import { getApp, initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG || "");

function initApp() {
  try {
    return getApp();
  } catch (e) {
    return initializeApp(firebaseConfig);
  }
}

// Initialize Firebase
export const app = initApp();

export const auth = getAuth(app);
auth.setPersistence(browserLocalPersistence);
